import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import Preloader from 'src/components/preloaders/Preloader';
import SnackbarContext from 'src/contexts/SnackbarContext';
import NewsBlogItem from 'src/components/home/NewsBlogItem';
import { checkAuthError } from 'src/utilize/helper-functions';
import useLogout from 'src/hooks/useLogout';

import arrow from '@assets/images/icons/index-arrow.svg';

import { BirthdayCardItem } from '@components/home/BirthdayCardItem';

import { useSelector } from 'react-redux';

import { Container } from '@shared/components';

import ProjectListItem from '../Projects/project-list/project-list-container/ProjectListItem';

import {
  StyledBirthdaysWrapper,
  StyledIndexLayout,
  StyledIndexSection,
  StyledPostWrapper,
  StyledProjectScroll,
  StyledProjectWrapper,
  StyledTitle,
  StyledTitleWrapper,
} from './styles';

const Home = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [summary, setSummary] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const logout = useLogout();

  const birthdays = useSelector((state) => state.users.birthdays);

  const hasBirthdays = birthdays?.length > 0 || summary?.birthdays?.length > 0;

  useEffect(() => {
    axios
      .get('/api/main_page')
      .then((r) => {
        setSummary(r.data.mainPageData);
      })
      .catch((error) => {
        if (checkAuthError(error)) logout();
        else showSnackbar('Возникла ошибка при загрузке данных');
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading && <Preloader />}
      {summary && (
        <StyledIndexLayout showBirthdays={hasBirthdays}>
          <StyledIndexSection priority={1}>
            <StyledTitleWrapper>
              <StyledTitle to="/news" icon={arrow}>
                Новости
              </StyledTitle>
            </StyledTitleWrapper>
            <StyledPostWrapper>
              {summary.news.map((newsItem, i) => (
                <NewsBlogItem newsBlogItem={newsItem} key={i} type="news" />
              ))}
            </StyledPostWrapper>
          </StyledIndexSection>
          <StyledIndexSection priority={2}>
            <StyledTitleWrapper>
              <StyledTitle to="/blogs" icon={arrow}>
                Блог
              </StyledTitle>
            </StyledTitleWrapper>
            <StyledPostWrapper>
              {summary.blogs.map((blog, i) => (
                <NewsBlogItem newsBlogItem={blog} key={i} type="blogs" />
              ))}
            </StyledPostWrapper>
          </StyledIndexSection>

          {hasBirthdays && (
            <StyledIndexSection priority={3}>
              <StyledTitleWrapper>
                <StyledTitle>Дни рождения сегодня</StyledTitle>
              </StyledTitleWrapper>
              <StyledBirthdaysWrapper>
                <Container direction="column" gap={'20px'}>
                  {(birthdays?.length ? birthdays : summary?.birthdays).map((birthday) => (
                    <BirthdayCardItem key={birthday.id} birthday={birthday} />
                  ))}
                </Container>
              </StyledBirthdaysWrapper>
            </StyledIndexSection>
          )}

          <StyledIndexSection priority={hasBirthdays ? 5 : 3}>
            <StyledTitleWrapper>
              <StyledTitle to="/projects" icon={arrow}>
                Проекты
              </StyledTitle>
            </StyledTitleWrapper>
            <StyledProjectScroll>
              <StyledProjectWrapper>
                {summary.projects.map((project, i) => (
                  <ProjectListItem item={project} key={i} />
                ))}
              </StyledProjectWrapper>
              <StyledTitle to="/projects" icon={arrow} margin="20px 0">
                Все проекты
              </StyledTitle>
            </StyledProjectScroll>
          </StyledIndexSection>
        </StyledIndexLayout>
      )}
    </>
  );
};

export default Home;
