import { useEffect, useState, useRef, memo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledAsideForwardButton,
  StyledIcon,
  StyledProjectTree,
  StyledProjectTreeItem,
  StyledProjectTreeTitle,
} from '@components/chat/side-menu/styles';
import iconDone from '@assets/images/icons/check-green.svg';
import iconLink from '@assets/images/icons/icon-new-window.svg';
import iconArrow from '@assets/images/icons/arrow.svg';

import { getProjectStructure } from 'src/redux/features/projectsSlice';
import { useProjectStructureContext } from 'src/contexts/ProjectStructureContext';
import Preloader from 'src/components/preloaders/Preloader';
import { arraysEqual } from 'src/utilize/helper-functions';

import { useMessageContext } from '../../../contexts/MessageContext';

// используется рекурсивно для рендера ветвей дерева
// контейнер используется чтобы все под-компоненты лишний раз не обновлялись при
// обновлении react контекста useProjectStructure
const TaskTreeItemContainer = (props) => {
  const { selectedTask, setSelectedTask } = useProjectStructureContext();

  return <MemoizedTaskTreeItem {...props} selectedTask={selectedTask} setSelectedTask={setSelectedTask} />;
};

// обновлять компонент MemoizedTaskTreeItem, если:
// 1) id выбранной задачи не соответствовала этому компоненту и теперь соответствует
// 2) id выбранной задачи соответствовала этому компоненту и теперь не соответствует
// 3) массив подзадач обновился
const memoSettings = (prevProps, nextProps) => {
  if (
    (!prevProps.selectedTask || prevProps.selectedTask !== prevProps.id) &&
    nextProps.selectedTask &&
    nextProps.selectedTask === nextProps.id
  ) {
    return false;
  } else if (prevProps.selectedTask === prevProps.id && nextProps.selectedTask !== nextProps.id) {
    return false;
  } else if (arraysEqual(prevProps.tasks, nextProps.tasks)) return true;
};

const TaskTreeItem = ({
  title,
  tasks,
  status,
  id,
  type,
  projectId,
  toggleParent,
  selectedTask,
  showRelevantTaskInfo,
  openSideMenu,
}) => {
  const [subtasks, toggleSubtasks] = useState(() => {
    // загружаем состояние из localStorage при инициализации
    const savedOpenTasks = JSON.parse(localStorage.getItem('openTasks')) || {};
    return !!savedOpenTasks[id];
  });

  const paragraph = useRef();
  const { taskId } = useParams();

  // если в поле ввода остался текст, файлы, цитирование и тд, сбрасываем их при переходе в другую задачу
  const { resetEditor } = useMessageContext();

  // если есть подзадачи, то показать стрелку рядом с этой задачей
  useEffect(() => {
    if (tasks.length) {
      paragraph.current.dataset.spoiler = true;
    }
  }, [tasks]);

  const toggleChildren = (status) => {
    toggleSubtasks(status);
    if (toggleParent) toggleParent(status);
  };

  // сохранение состояния задач в localStorage при изменении
  useEffect(() => {
    const savedOpenTasks = JSON.parse(localStorage.getItem('openTasks')) || {};
    savedOpenTasks[id] = subtasks;
    localStorage.setItem('openTasks', JSON.stringify(savedOpenTasks));
  }, [subtasks, id]);

  // при первоначальной загрузке данных, если id открытой задачи соответствует элементу в дереве,
  // то раскрыть всю ветку задач в сайдбаре
  useEffect(() => {
    if (+taskId === id) {
      if (toggleParent) {
        toggleParent(true);
      }
    }
  }, [taskId, id, toggleParent]);

  // для определения одиночного и двойного клика
  const pendingClick = useRef();
  const clicked = useRef(0);

  const navigate = useNavigate();

  const onSingleClick = () => {
    clicked.current++;
    if (clicked.current >= 2) {
      if (showRelevantTaskInfo) {
        showRelevantTaskInfo(type, id, title);
        resetEditor && resetEditor.current();
      }
      clearTimeout(pendingClick.current);
      clicked.current = 0;
      return;
    }

    if (tasks.length) {
      pendingClick.current = setTimeout(() => {
        toggleSubtasks((subtasks) => !subtasks);
        clicked.current = 0;
      }, 250); // Задержка в 250 мс для отличия одиночного клика от двойного
    }
  };

  const onDoubleClick = () => {
    clearTimeout(pendingClick.current);
    clicked.current = 0;

    if (!showRelevantTaskInfo) {
      navigate(`/projects/${projectId}/tasks/${id}`);
      resetEditor && resetEditor.current();
      if (window.innerWidth < 1220) openSideMenu(null);
    }
  };

  return (
    <StyledProjectTreeItem $active={subtasks} icon={iconArrow}>
      <StyledProjectTreeTitle
        ref={paragraph}
        onClick={onSingleClick}
        onDoubleClick={onDoubleClick}
        style={{
          backgroundColor: selectedTask === id ? '#BAE7FF' : '',
        }}
      >
        {title}
        {/* показывать кнопку перехода на задачу, только если мы уже не находимся в данной задаче */}
        {(!taskId || id !== +taskId) && (
          <Link
            to={`/projects/${projectId}/tasks/${id}`}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <StyledIcon iconhover={iconLink} icondone={iconDone} $done={status === 'finished'} />
          </Link>
        )}
      </StyledProjectTreeTitle>

      <ul
        style={{
          transition: 'max-height 0.3s ease-in-out',
          maxHeight: subtasks ? '5000px' : '0',
          overflow: subtasks ? 'auto' : 'hidden',
          pointerEvents: subtasks ? 'auto' : 'none',
        }}
      >
        {tasks.map((task, i) => (
          <TaskTreeItemContainer
            key={i}
            {...task}
            projectId={projectId}
            toggleParent={toggleChildren}
            showRelevantTaskInfo={showRelevantTaskInfo}
            openSideMenu={openSideMenu}
          />
        ))}
      </ul>
    </StyledProjectTreeItem>
  );
};

// используется React memo, чтобы все ветви дерева не рендерились в лишний раз,
// чтобы обновлялась только выбранная ветвь
const MemoizedTaskTreeItem = memo(TaskTreeItem, memoSettings);

// ProjectTree используется в нескольких местах:
// в боковой панели StructureSidePanel - без возможности детализации выбранной задачи
// во всех остальных боковых панелях -  с возможностью отображения детализации по выбранной задаче
const ProjectTree = ({ update, showRelevantTaskInfo, showProjectTree, openSideMenu }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // если в поле ввода остался текст, файлы, цитирование и тд, сбрасываем их при переходе в другую задачу
  const { resetEditor } = useMessageContext();

  const { selectedProject, setSelectedTask } = useProjectStructureContext();

  // для данных по структуре проекта используется redux
  const { projectStructure, isLoadingProjectStructure, projectStructureError } = useSelector((state) => state.projects);

  const { projectId, taskId } = useParams();

  // получение данных по структуре проекта из сервера
  useEffect(() => {
    if (update && projectId) {
      dispatch(getProjectStructure({ projectId, taskId }));
    }
  }, [update, projectId, taskId, dispatch]);

  const [isProjectOpen, setIsProjectOpen] = useState(true);

  return (
    <>
      {/* показывать кнопку открытия текущей задачи, если это предусмотрено текущей боковой панелью  */}
      {showRelevantTaskInfo && (
        <StyledAsideForwardButton onClick={() => showProjectTree(false)}>Закрыть структуру</StyledAsideForwardButton>
      )}
      <StyledProjectTree data-spoilers>
        {isLoadingProjectStructure && <Preloader />}

        {projectStructureError && <div>{projectStructureError}</div>}

        {projectStructure && (
          <StyledProjectTreeItem $active={isProjectOpen} icon={iconArrow}>
            <StyledProjectTreeTitle
              style={{
                backgroundColor: selectedProject === +projectId && !taskId ? '#BAE7FF' : '',
              }}
              data-spoiler
              onDoubleClick={() => {
                if (showRelevantTaskInfo) {
                  showRelevantTaskInfo(projectStructure.type, projectStructure.id, projectStructure.title);
                  resetEditor && resetEditor.current();
                } else {
                  setSelectedTask(null);
                  navigate(`/projects/${projectId}`);
                  resetEditor && resetEditor.current();
                  if (window.innerWidth < 1220) openSideMenu(null);
                }
              }}
              onClick={() => setIsProjectOpen((prev) => !prev)}
            >
              {projectStructure.title}
              {/* показывать кнопку перехода на корень проекта, только если мы находимся не в корне проекта (т.е. в задаче) */}
              {taskId ? (
                <Link
                  to={`/projects/${projectStructure.id}`}
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <StyledIcon
                    iconhover={iconLink}
                    icondone={iconDone}
                    $done={projectStructure?.status === 'finished'}
                    onClick={() => {
                      setSelectedTask(null);
                      resetEditor && resetEditor.current();
                    }}
                  />
                </Link>
              ) : (
                <StyledIcon icondone={iconDone} $done={projectStructure?.status === 'finished'} />
              )}
            </StyledProjectTreeTitle>
            {projectStructure.tasks.length > 0 && (
              <ul
                style={{
                  transition: 'max-height 0.3s ease-in-out',
                  maxHeight: isProjectOpen ? '5000px' : '0',
                  overflow: isProjectOpen ? 'auto' : 'hidden',
                  pointerEvents: isProjectOpen ? 'auto' : 'none',
                }}
              >
                {projectStructure.tasks.map((task, i) => (
                  <TaskTreeItemContainer
                    key={i}
                    {...task}
                    projectId={projectStructure.id}
                    showRelevantTaskInfo={showRelevantTaskInfo}
                    openSideMenu={openSideMenu}
                  />
                ))}
              </ul>
            )}
          </StyledProjectTreeItem>
        )}
      </StyledProjectTree>
    </>
  );
};

export default ProjectTree;
