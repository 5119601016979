import { Routes, Route, useLocation } from 'react-router-dom';
// import { PageTickets } from 'src/pages/PageTickets';
// import { PageTicketsForMe } from 'src/pages/PageTickets/PageTicketsForMe';
import { PageVersions } from 'src/pages/PageVersions';
import { PageRequests } from 'src/pages/PageRequests/PageRequests';
import { PageNotifications } from 'src/pages/PageNotifications/PageNotifications';
import Subscriptions from 'src/pages/PageNotifications/Subscriptions/Subscriptions';
import Team from 'src/pages/PageTeam';
import TeamMain from 'src/pages/PageTeam/TeamMain';
import GuestList from 'src/pages/PageTeam/PageGuest';
import TeamTree from 'src/pages/PageTeam/TeamMain/TeamTree';
import PageSalaries from 'src/pages/PageTeam/PageSalaries';
import Home from 'src/pages/Home/Home';
import Login from 'src/pages/Login/Login';
import NotFound from 'src/pages/NotFound';
import Forgot from 'src/pages/Forgot/Forgot';
import Reminders from 'src/pages/PageReminders';
import Favorites from 'src/pages/Favorites/Favorites';
import Alerts from 'src/pages/Alerts/Alerts';
import Tasks from 'src/pages/Tasks/Tasks';
import Vacations from 'src/pages/PageTeam/vacations';
import ProjectsRoutes from 'src/pages/Projects/ProjectsRoutes';
import NewsRoutes from 'src/pages/NewsBlogs/NewsBlogsRoutes';
import RestorePassword from 'src/pages/restore-password/restore-password';
import StorageRoutes from 'src/pages/Storage/StorageRoutes';
import PagePayments from 'src/pages/PageTeam/PagePayments';
import PrivateRoute from 'src/routes/components/PrivateRoute';
import Nav from 'src/components/nav/nav';
import { Surveys } from 'src/pages/Surveys/Surveys';
import SurveyChatPage from 'src/pages/Surveys/SurveyChatPage/SurveyChatPage';
import { PagePreview } from 'src/pages/PagePreview';
import { PageLinks } from 'src/pages/PageLinks';
import { AllReminders } from '@components/Reminders/AllReminders';
import { Contacts } from 'src/pages/Contacts';
import { TelegramLoginPage } from 'src/pages/TelegramLogin';

import BirthdayChatPage from 'src/pages/BirthdayChatPage';

import { HistorySidebar } from '@components/HistorySidebar';

import { useSelector, useDispatch } from 'react-redux';

import { setHistorySidebarOpen } from 'src/redux/features/commonSlice';

import OfficeRoutes from '../../pages/Projects/OfficeRoutes';

import { Content, Layout } from './styles';

const AllRouters = ({ popupWindow }) => {
  const isHistorySidebarOpen = useSelector((state) => state.common.isHistorySidebarOpen);
  const dispatch = useDispatch();

  let location = useLocation();
  let state = location.state; // откуда user перешел на страницу, предыдущий адрес

  return (
    <Routes location={state?.backgroundLocation || location}>
      <Route path="/login/" element={<Login />} />

      <Route path={'/restore_password/:token'} element={<RestorePassword />} />

      <Route exact path="/forgot" element={<Forgot />} />

      <Route path={'/tg-login'} element={<TelegramLoginPage />} />

      <Route
        path="*"
        element={
          <>
            <Nav popupWindow={popupWindow} />

            <AllReminders />

            <Layout>
              <Content isHistorySidebarOpen={isHistorySidebarOpen}>
                <Routes location={state?.backgroundLocation || location}>
                  <Route
                    exact
                    path="/"
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/preview"
                    element={
                      <PrivateRoute>
                        <PagePreview />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/links"
                    element={
                      <PrivateRoute>
                        <PageLinks />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={'/team'}
                    element={
                      <PrivateRoute>
                        <Team />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="users/:userId?"
                      element={
                        <PrivateRoute>
                          <TeamMain />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="guests/:guest_id?"
                      element={
                        <PrivateRoute>
                          <GuestList />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="tree"
                      element={
                        <PrivateRoute>
                          <TeamTree />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="salaries"
                      element={
                        <PrivateRoute>
                          <PageSalaries />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="payments"
                      element={
                        <PrivateRoute>
                          <PagePayments />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="vacations/*"
                      element={
                        <PrivateRoute>
                          <Vacations />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                  <Route
                    path="/contacts"
                    element={
                      <PrivateRoute>
                        <Contacts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/contacts/:contactId"
                    element={
                      <PrivateRoute>
                        <Contacts />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/projects/*" element={<ProjectsRoutes popupWindow={popupWindow} />} />
                  <Route path="/offices/*" element={<OfficeRoutes popupWindow={popupWindow} />} />

                  <Route
                    path="/reminders"
                    element={
                      <PrivateRoute>
                        <Reminders />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/favorites"
                    element={
                      <PrivateRoute>
                        <Favorites />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/alerts"
                    element={
                      <PrivateRoute>
                        <Alerts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/tasks"
                    element={
                      <PrivateRoute>
                        <Tasks />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/news/*"
                    element={
                      <PrivateRoute>
                        <NewsRoutes />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/blogs/*"
                    element={
                      <PrivateRoute>
                        <NewsRoutes />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/surveys/*"
                    element={
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <PrivateRoute>
                              <Surveys />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/:surveyId"
                          element={
                            <PrivateRoute>
                              <SurveyChatPage />
                            </PrivateRoute>
                          }
                        />
                      </Routes>
                    }
                  />

                  <Route
                    path="/birthday/*"
                    element={
                      <PrivateRoute>
                        <Routes>
                          <Route
                            path="/:birthdayId"
                            element={
                              <PrivateRoute>
                                <BirthdayChatPage />
                              </PrivateRoute>
                            }
                          />
                        </Routes>
                      </PrivateRoute>
                    }
                  />
                  {/* <Route
                path="/tickets"
                element={
                  <PrivateRoute>
                    <PageTickets />
                  </PrivateRoute>
                }
              />
              <Route
                path="/tickets/me"
                element={
                  <PrivateRoute>
                    <PageTicketsForMe />
                  </PrivateRoute>
                }
              /> */}
                  <Route
                    path="/requests/:request_type?/:page?"
                    element={
                      <PrivateRoute>
                        <PageRequests />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/notifications"
                    element={
                      <PrivateRoute>
                        <PageNotifications />
                      </PrivateRoute>
                    }
                  />

                  {/* <Route
                    path="/users-rights"
                    element={
                      <PrivateRoute>
                        <UsersRights />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/users-activity"
                    element={
                      <PrivateRoute>
                        <UsersActivity />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/users-engagement"
                    element={
                      <PrivateRoute>
                        <UsersEngagement />
                      </PrivateRoute>
                    }
                  /> */}

                  <Route
                    path="/informator"
                    element={
                      <PrivateRoute>
                        <PageNotifications />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/notifications/subscriptions"
                    element={
                      <PrivateRoute>
                        <Subscriptions />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/versions"
                    element={
                      <PrivateRoute>
                        <PageVersions />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/storages/*"
                    element={
                      <PrivateRoute>
                        <StorageRoutes />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <NotFound />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </Content>

              {isHistorySidebarOpen && <HistorySidebar onClose={() => dispatch(setHistorySidebarOpen(false))} />}
            </Layout>
          </>
        }
      />
    </Routes>
  );
};

export default AllRouters;